import { RuleResultInterface, RuleInterface, RuleHOFInterface } from "@Rules"

export const equal: RuleHOFInterface = (match: any): RuleInterface => {
  const fn: RuleInterface = (value: any): RuleResultInterface => {
    return {
      valid: value === match,
      message: `${value} should be equals to ${match}`,
    }
  }
  return fn
}

import * as Rules from "@Rules"
import { DefinitionsInterface } from "@Interface"
import { mergeProps } from "@CheckEvent"

const product = [
  { key : "products",
    values: [
      { key: "list_name", rules: [Rules.isString] },
      { key: "position", rules: [Rules.isNumber] },
      { key: "price", rules: [Rules.isNumber] },
      { key: "estate_type", rules: [Rules.isNumber] },
      { key: "distribution_type", rules: [Rules.isNumber] },
      { key: "space", optional: true, rules: [Rules.isNumber] },
      { key: "id", rules: [Rules.isNumber] },
      { key: "client_id", rules: [Rules.isNumber] },
      { key: "photos_nb", rules: [Rules.isNumber] },
      { key: "estate_department", optional:true, rules: [Rules.isNumber] },
      { key: "estate_postalcode", rules: [Rules.isString] },
      { key: "client_postalcode", optional: true, rules: [Rules.isString] },
      { key: "country", rules: [Rules.isString] },
      { key: "idbandeau", rules: [Rules.isNumber] },
      { key: "client_type", rules: [Rules.isString] },
      { key: "investment_type", rules: [Rules.isString] },
      { key: "business_type", rules: [Rules.isString] },
      { key: "parent_site", rules: [Rules.isString] },
      { key: "year_of_construction", rules: [Rules.isNumber] },
      { key: "floor_nb", optional: true, rules: [Rules.isNumber] },
      { key: "nb_rooms", rules: [Rules.isNumber] }, 
      { key: "energy_letter", optional: true, rules: [Rules.isString] }, 
      { key: "nb_bedrooms", optional: true, rules: [Rules.isNumber] },
      { key: "heating_type", optional: true, rules: [Rules.isString] },
      { key: "product_status", rules: [Rules.isString] },
      { key: "media", rules: [Rules.isBoolean] },
      { key: "product_type", rules: [Rules.isString] },
      { key: "product_geolocation", rules: [Rules.isString] }
    ]
  }
]

export const SLC:DefinitionsInterface = {
  view: {
    event: "view",
    props: [
      { key :"site", rules: [ Rules.equal('SLC') ] },
      { key : "products", optional: [Rules.isNotSearchPage, Rules.isNotDetailPage], optionalArg: "pagename",
            values: [
                { key: "idtiers", rules: [Rules.isNumber] },
                { key: "idagence", rules: [Rules.isNumber] },
                { key: "estate_department", optional: true, rules: [Rules.isNumber] },
                { key: "client_postalcode", rules: [Rules.isString] },
                { key: "business_type", optional: true, rules: [Rules.isStringNumber] },
                { key: "investment_type", optional: true, rules: [Rules.isStringNumber] },
                { key: "year_of_construction", optional: true, rules: [Rules.isNumber] },
                { key: "floor_nb", optional: true, rules: [Rules.isNumber] },
                { key: "heating_type", optional: true, rules: [Rules.isString] },
                { key: "energy_letter", optional: true, rules: [Rules.isString] },
                { key: "media", rules: [Rules.isBoolean] },
                { key: "rating", optional: true, rules: [Rules.isNumber] }
            ]
	  }
    ]
    
  },
  interactions: [
    {
      event: "interaction",
      int_type: "phone",
      int_description: ["conversion"],
      props: mergeProps( product,
        [
          { key: "int_infos", rules: [ Rules.isInArray([ "detail-reponsive", "detail_client-top", "detail_client-estate","detail_client-responsive", "detail-agency_infos", "detail_client-bottom", "search_results_client-phone" ]) ]
          }
        ]
      )
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["interest"], 
      props: mergeProps( product,
        [
          { key: "int_infos", rules: [ Rules.isInArray([ "detail-plan"," detail-right", "detail-get_adress", "detail-reponsive", "detail_client-top", "detail_client-bottom", "detail_client-post_phone", "detail_client-estate", "detail_client-responsive" ]) ]
          },
        ]
      )
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["conversion", "confirmation"],
      props: mergeProps( product,
        [
          { key: "int_infos", rules: [ Rules.isInArray([ "detail-plan"," detail-right", "detail-get_adress", "detail-reponsive", "detail_client-top", "detail_client-bottom", "detail_client-post_phone", "detail_client-estate", "detail_client-responsive" ]) ]
          },
          { key: "email_lead", optional: [ Rules.match(/^detail_client.*/) ], optionalArg: "int_infos", rules: [ Rules.isMd5 ] }
        ]
      )
    },

    {
      event: "interaction",
      int_type: "ali",
      int_description: ["interest"],
      props: [
        { key: "int_infos", rules: [ Rules.isInArray([ "search_results-bottom"," search_results-list", "search_results-auto-promo" ]) ] }
      ]
    },
    {
      event: "interaction",
      int_type: "ali",
      int_description: ["subscription"],
      props: [
        { key: "int_infos", rules: [ Rules.isInArray([ "search_results-manual", "detail-auto", "detail_client-auto" ]) ] },
        { key: "email_lead", rules: [ Rules.isMd5 ] }
      ]
    },
    
    {
      event: "interaction",
      int_type: "product",
      int_description: ["click"],
      props: mergeProps( product,
        [
          { key: "int_infos", rules: [ Rules.isInArray([ "search_results-list", "search_results-map", "detail", "detail_client", "search_results_client" ] ) ] } 
        ]
      )
    }
  ]
}
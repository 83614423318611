import * as Rules from "@Rules"
import { DefinitionsInterface } from "@Interface"
import { mergeProps } from "@CheckEvent"

const product = [
  {
    key: "products",
    values: [
      { key: "list_name", rules: [Rules.isString] },
      { key: "position", rules: [Rules.isNumber] },
      { key: "price", rules: [Rules.isNumber] },
      { key: "estate_type", rules: [Rules.isNumber] },
      { key: "distribution_type", rules: [Rules.isNumber] },
      { key: "space", optional: true, rules: [Rules.isNumber] },
      { key: "id", rules: [Rules.isNumber] },
      { key: "client_id", rules: [Rules.isNumber] },
      { key: "photos_nb", rules: [Rules.isNumber] },
      { key: "estate_department", optional: true, rules: [Rules.isNumber] },
      { key: "estate_postalcode", rules: [Rules.isString] },
      { key: "client_postalcode", optional: true, rules: [Rules.isString] },
      { key: "country", rules: [Rules.isString] },
      { key: "client_type", rules: [Rules.isString] },
      { key: "investment_type", rules: [Rules.isString] },
      { key: "business_type", rules: [Rules.isString] },
      { key: "parent_site", rules: [Rules.isString] },
      { key: "year_of_construction", rules: [Rules.isNumber] },
      { key: "floor_nb", optional: true, rules: [Rules.isNumber] },
      { key: "nb_rooms", rules: [Rules.isNumber] },
      { key: "energy_letter", optional: true, rules: [Rules.isString] },
      { key: "nb_bedrooms", optional: true, rules: [Rules.isNumber] },
      { key: "heating_type", optional: true, rules: [Rules.isString] },
      { key: "product_status", rules: [Rules.isString] },
      { key: "media", optional: [Rules.isSearchClientPage], rules: [Rules.isString] },
      { key: "product_type", rules: [Rules.isString] },
    ],
  },
]

export const SLN: DefinitionsInterface = {
  view: {
    event: "view",
    props: [
      { key: "site", rules: [Rules.equal("SLN")] },
      { key: "abtest", optional: true, rules: [Rules.isString, Rules.isNumber] },
      {
        key: "products",
        optional: [Rules.isNotSearchPage, Rules.isNotDetailPage],
        optionalArg: "pagename",
        values: [
          { key: "idtiers", rules: [Rules.isNumber] },
          { key: "idagence", rules: [Rules.isNumber] },
          { key: "investment_type", rules: [Rules.isString] },
          { key: "estate_department", optional: true, rules: [Rules.isNumber] },
          { key: "client_postalcode", rules: [Rules.isString] },
          { key: "delivery_date", optional: true, rules: [Rules.isString] },
          { key: "media", optional: [Rules.isSearchClientPage], rules: [Rules.isString] },
        ],
      },
      {
        key: "search",
        missing: [Rules.isNotSearchPage],
        missingArg: "pagename",
        optional: [Rules.isNotSearchPage],
        optionalArg: "pagename",
        values: [{ key: "distribution_type", optional: [Rules.isSearchClientPage], optionalArg: "pagename", rules: [Rules.isNumber] }],
      },
    ],
  },
  interactions: [
    {
      event: "interaction",
      int_type: "phone",
      int_description: ["conversion", "interest-snapcall"],
      props: mergeProps(product, [
        { key: "int_infos", rules: [Rules.isInArray(["detail-responsive", "detail-right", "detail-form-agency", "detail-sticky", "detail_client"])] },
      ]),
    },
    {
      event: "interaction",
      int_type: "phone",
      int_description: ["confirmation"],
      props: mergeProps(product, [
        { key: "int_infos", rules: [Rules.isInArray(["detail-responsive", "detail-right", "detail-form-agency", "detail-bottom"])] },
      ]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["interest"],
      props: mergeProps(product, [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray([
              "detail-plan",
              "detail-right",
              "detail-reponsive",
              "detail-defiscalisation",
              "search_results_client",
              "detail_client",
              "detail-plan-download",
              "detail-right-download",
              "detail-reponsive-download",
              "detail-defiscalisation-download",
              "detail-description",
              "detail-description-download",
              "detail-housing",
              "detail-housing-download",
            ]),
          ],
        },
      ]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["conversion", "confirmation"],
      props: mergeProps(product, [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray([
              "detail-plan",
              "detail-right",
              "detail-brochure",
              "detail-reponsive",
              "detail-defiscalisation",
              "search_results_client",
              "detail_client",
              "detail-plan-download",
              "detail-right-download",
              "detail-brochure-download",
              "detail-reponsive-download",
              "detail-defiscalisation-download",
              "detail-description",
              "detail-description-download",
              "detail-housing",
              "detail-housing-download",
              "invest_form",
              "detail-lateral",
            ]),
          ],
        },
        { key: "email_lead", optional: [Rules.match(/^detail_client.*/)], optionalArg: "int_infos", rules: [Rules.isMd5] },
      ]),
    },

    {
      event: "interaction",
      int_type: "product",
      int_description: ["click"],
      props: mergeProps(product, [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray([
              "detail_similar_bottom",
              "detail-similars_bottom",
              "search_results",
              "search_results_map",
              "homepage",
              "recommendation",
              "account-favorites",
              "account-saved_search",
              "account-last_seen",
              "detail_client-estate",
              "search_result_client",
              "search_results-list",
            ]),
          ],
        },
      ]),
    },
  ],
}

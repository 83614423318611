import * as Definitions from "./definitions"
import { DefinitionsLeadInterface, DefinitionsViewInterface, TestingInterface } from "@Interface"
import { testLead, testView, mergeInteractions, mergeProps, showUnknownProp } from "@CheckEvent"
import { displayStart, displayEnd } from "@Src/display"
import { errorStyle, successStyle, infoStyle } from "@Src/styles"

export const ThorTesting = (config: string): TestingInterface => {
  let currentConfig = Definitions["common"]
  if (!!!Definitions[config]) console.warn(`Thor-testing doesn't have any "${config}" config`)
  else {
    if (!!Definitions[config].view) currentConfig.view.props = mergeProps(Definitions["common"].view.props, Definitions[config].view.props)
    if (!!Definitions[config].interactions)
      currentConfig.interactions = mergeInteractions(Definitions["common"].interactions, Definitions[config].interactions)
  }

  const testing: TestingInterface = (event: string, data: { [key: string]: any }) => {
    const definitionsLead: DefinitionsLeadInterface = currentConfig.interactions.find(
      (list: any) =>
        list.event === event &&
        list.int_type === data.int_type &&
        (Array.isArray(list.int_description) ? list.int_description.includes(data.int_description) : list.int_description.test(data.int_description))
    )
    const definitionView: DefinitionsViewInterface = currentConfig.view

    displayStart(event === "view" ? `${event}-->${data.pagename}` : `${event}-->${data.int_type}-->${data.int_description}`)
    if (event === "view") {
      testView(data, definitionView)
      showUnknownProp(data, definitionView)
    } else if (!!!definitionsLead) console.log(`%c untracked lead event "${event}"`, errorStyle)
    else {
      definitionsLead.props = mergeProps(currentConfig.view.props, definitionsLead.props)
      testLead(data, definitionsLead)
      showUnknownProp(data, definitionsLead)
    }
    console.log(`%c DATALAYER: `, infoStyle, data)
    displayEnd(event === "view" ? `${event}-->${data.pagename}` : `${event}-->${data.int_type}-->${data.int_description}`)
  }
  return testing
}

const evt = new Event("thorTestingLoaded")
window.dispatchEvent(evt)
console.log(`%c THOR-TESTING LIB FULLY LOADED - VERSION : ${process?.env?.VERSION}`, successStyle)
console.log("%c READY TO PARTY ", successStyle)

import * as Rules from "@Rules"
import { DefinitionsInterface } from "@Interface"
import { mergeProps } from "@CheckEvent"

const product = [
  {
    key: "products",
    values: [
      { key: "list_name", rules: [Rules.isString] },
      { key: "position", rules: [Rules.isNumber] },
      { key: "price", rules: [Rules.isNumber] },
      { key: "estate_type", rules: [Rules.isNumber] },
      { key: "distribution_type", rules: [Rules.isNumber] },
      { key: "space", optional: true, rules: [Rules.isNumber] },
      { key: "id", rules: [Rules.isNumber] },
      { key: "client_id", rules: [Rules.isNumber] },
      { key: "photos_nb", rules: [Rules.isNumber] },
      { key: "estate_department", optional: true, rules: [Rules.isNumber] },
      { key: "estate_postalcode", rules: [Rules.isString] },
      { key: "client_postalcode", optional: true, rules: [Rules.isString] },
      { key: "country", rules: [Rules.isString] },
      { key: "idbandeau", optional: true, rules: [Rules.isNumber] },
      { key: "client_type", rules: [Rules.isString] },
      { key: "investment_type", optional: true, rules: [Rules.isString] },
      { key: "business_type", optional: true, rules: [Rules.isString] },
      { key: "parent_site", rules: [Rules.isString] },
      { key: "year_of_construction", optional: true, rules: [Rules.isNumber] },
      { key: "floor_nb", optional: true, rules: [Rules.isNumber] },
      { key: "nb_rooms", rules: [Rules.isNumber] },
      { key: "energy_letter", optional: true, rules: [Rules.isString] },
      { key: "nb_bedrooms", optional: true, rules: [Rules.isNumber] },
      { key: "heating_type", optional: true, rules: [Rules.isString] },
      { key: "product_status", rules: [Rules.isString] },
      { key: "media", rules: [Rules.isBoolean] },
      { key: "product_type", rules: [Rules.isString] },
      { key: "product_geolocation", optional: true, rules: [Rules.isString] },
    ],
  },
]

export const BD: DefinitionsInterface = {
  view: {
    event: "view",
    props: [
      { key: "site", rules: [Rules.equal("BD")] },
      {
        key: "products",
        optional: [Rules.isNotSearchPage, Rules.isNotDetailPage],
        optionalArg: "pagename",
        values: [
          { key: "idtiers", rules: [Rules.isNumber] },
          { key: "idagence", rules: [Rules.isNumber] },
          { key: "media", rules: [Rules.isBoolean] },
          { key: "client_postalcode", rules: [Rules.isString] },
        ],
      },
      {
        key: "search",
        missing: [Rules.isNotSearchPage],
        missingArg: "pagename",
        optional: [Rules.isNotSearchPage],
        optionalArg: "pagename",
        values: [{ key: "distribution_type", optional: [Rules.isSearchClientPage], optionalArg: "pagename", rules: [Rules.isNumber] }],
      },
    ],
  },
  interactions: [
    {
      event: "interaction",
      int_type: "phone",
      int_description: ["conversion"],
      props: mergeProps(product, [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray([
              "detail-right",
              "detail-sticky",
              "detail-modale",
              "detail-responsive",
              "detail-agency_block",
              "detail-caroussel",
              "detail_client-right",
              "detail_client-responsive",
              "detail_client_review-right",
              "detail-similars_bottom",
              "detail-responsive_bottom",
              "search_results",
              "detail_client-estate",
              "detail-gallery",
              "detail-lightbox",
              "detail-responsive-carrousel",
              "detail-responsive-lightbox",
            ]),
          ],
        },
      ]),
    },
    {
      event: "interaction",
      int_type: "navigation",
      int_description: ["click"],
      props: mergeProps(product, [{ key: "int_infos", rules: [Rules.isNotEmptyString] }]),
    },
    {
      event: "interaction",
      int_type: "product",
      int_description: ["impression"],
      props: mergeProps(product, [{ key: "int_infos", rules: [Rules.isInArray(["search_results"])] }]),
    },
    {
      event: "interaction",
      int_type: "product",
      int_description: ["click"],
      props: mergeProps(product, [{ key: "int_infos", rules: [Rules.isNotEmptyString] }]),
    },
    {
      event: "interaction",
      int_type: "favorite",
      int_description: ["add"],
      props: mergeProps(product, [{ key: "int_infos", rules: [Rules.isInArray(["search_results", "detail", "detail_client"])] }]),
    },
    {
      event: "interaction",
      int_type: "favorite",
      int_description: ["remove"],
      props: mergeProps(product, [{ key: "int_infos", rules: [Rules.isInArray(["search_results", "detail", "detail_client", "favorites"])] }]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["interest"],
      props: mergeProps(product, [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray([
              "detail_right",
              "detail-responsive",
              "detail-post_contact",
              "detail-get_adress",
              "detail-get_infos",
              "detail_client-get_selling_price",
              "detail_client-responsive",
              "search_results",
              "detail_client-estate",
              "detail-get_plan",
              "detail-caroussel",
              "detail-post_phone",
              "detail_client-post_phone",
              "detail-responsive_bottom",
              "detail_client-responsive_bottom",
              "detail-similars_bottom",
              "search_results",
              "detail_client-estate",
              "detail-gallery",
              "detail-lightbox",
              "detail-responsive-carrousel",
              "detail-responsive-lightbox",
            ]),
          ],
        },
      ]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["confirmation"],
      props: mergeProps(product, [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray([
              "detail-right",
              "detail-bottom",
              "detail-post_contact",
              "detail_client-right",
              "detail_client-responsive",
              "detail-get_plan",
              "detail_client-review",
              "detail-get_adress",
              "detail-responsive_bottom",
              "detail-caroussel",
              "detail-similars_bottom",
              "detail-post_phone",
              "detail_client-post_phone",
            ]),
          ],
        },
        { key: "form_profile", optional: true, rules: [Rules.equal("seller")] },
        { key: "email_lead", optional: [Rules.match(/^detail_client.*/)], optionalArg: "int_infos", rules: [Rules.isMd5] },
      ]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["conversion"],
      props: mergeProps(product, [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray([
              "detail-right",
              "detail-bottom",
              "detail-post_contact",
              "detail_client-right",
              "detail_client-responsive",
              "detail-get_plan",
              "detail_client-review",
              "detail-get_adress",
              "detail-responsive_bottom",
              "detail-caroussel",
              "detail-similars_bottom",
              "detail-post_phone",
              "detail_client-post_phone",
              "search_results",
              "detail_client-estate",
              "detail_client_review-right",
              "detail-gallery",
              "detail-lightbox",
              "detail-responsive-carrousel",
              "detail-responsive-lightbox",
            ]),
          ],
        },
        { key: "form_profile", optional: true, rules: [Rules.equal("seller")] },
        { key: "email_lead", optional: [Rules.match(/^detail_client.*/)], optionalArg: "int_infos", rules: [Rules.isMd5] },
      ]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["seller"],
      props: mergeProps(product, [
        { key: "form_profile", optional: true, rules: [Rules.equal("seller")] },
        { key: "email_lead", optional: [Rules.match(/^detail_client.*/)], optionalArg: "int_infos", rules: [Rules.isMd5] },
      ]),
    },
    {
      event: "interaction",
      int_type: "ali",
      int_description: ["interest"],
      props: [
        { key: "int_infos", rules: [Rules.isNotEmptyString] },
        {
          key: "products",
          optional: [Rules.equal("search_results-manual")],
          optionalArg: "int_infos",
          values: [
            { key: "list_name", rules: [Rules.isString] },
            { key: "position", rules: [Rules.isNumber] },
            { key: "price", rules: [Rules.isNumber] },
            { key: "estate_type", rules: [Rules.isNumber] },
            { key: "distribution_type", rules: [Rules.isNumber] },
            { key: "space", optional: true, rules: [Rules.isNumber] },
            { key: "id", rules: [Rules.isNumber] },
            { key: "client_id", rules: [Rules.isNumber] },
            { key: "photos_nb", rules: [Rules.isNumber] },
            { key: "estate_department", optional: true, rules: [Rules.isNumber] },
            { key: "estate_postalcode", rules: [Rules.isString] },
            { key: "client_postalcode", optional: true, rules: [Rules.isString] },
            { key: "country", rules: [Rules.isString] },
            { key: "idbandeau", optional: true, rules: [Rules.isNumber] },
            { key: "client_type", rules: [Rules.isString] },
            { key: "investment_type", optional: true, rules: [Rules.isString] },
            { key: "business_type", optional: true, rules: [Rules.isString] },
            { key: "parent_site", rules: [Rules.isString] },
            { key: "year_of_construction", optional: true, rules: [Rules.isNumber] },
            { key: "floor_nb", optional: true, rules: [Rules.isNumber] },
            { key: "nb_rooms", rules: [Rules.isNumber] },
            { key: "energy_letter", optional: true, rules: [Rules.isString] },
            { key: "nb_bedrooms", optional: true, rules: [Rules.isNumber] },
            { key: "heating_type", optional: true, rules: [Rules.isString] },
            { key: "product_status", rules: [Rules.isString] },
            { key: "media", rules: [Rules.isBoolean] },
            { key: "product_type", rules: [Rules.isString] },
            { key: "product_geolocation", rules: [Rules.isString] },
            { key: "tenant_file", optional: true, rules: [Rules.isInArray(["yes", "no", "undefined"])] },
          ],
        },
      ],
    },
    {
      event: "interaction",
      int_type: "ali",
      int_description: ["subscription"],
      props: [
        { key: "int_infos", rules: [Rules.isNotEmptyString] },
        { key: "email_lead", rules: [Rules.isMd5] },
        {
          key: "products",
          optional: [Rules.match("search_results-manual")],
          optionalArg: "int_infos",
          values: [
            { key: "list_name", rules: [Rules.isString] },
            { key: "position", rules: [Rules.isNumber] },
            { key: "price", rules: [Rules.isNumber] },
            { key: "estate_type", rules: [Rules.isNumber] },
            { key: "distribution_type", rules: [Rules.isNumber] },
            { key: "space", optional: true, rules: [Rules.isNumber] },
            { key: "id", rules: [Rules.isNumber] },
            { key: "client_id", rules: [Rules.isNumber] },
            { key: "photos_nb", rules: [Rules.isNumber] },
            { key: "estate_department", optional: true, rules: [Rules.isNumber] },
            { key: "estate_postalcode", rules: [Rules.isString] },
            { key: "client_postalcode", optional: true, rules: [Rules.isString] },
            { key: "country", rules: [Rules.isString] },
            { key: "idbandeau", optional: true, rules: [Rules.isNumber] },
            { key: "client_type", rules: [Rules.isString] },
            { key: "investment_type", optional: true, rules: [Rules.isString] },
            { key: "business_type", optional: true, rules: [Rules.isString] },
            { key: "parent_site", rules: [Rules.isString] },
            { key: "year_of_construction", optional: true, rules: [Rules.isNumber] },
            { key: "floor_nb", optional: true, rules: [Rules.isNumber] },
            { key: "nb_rooms", rules: [Rules.isNumber] },
            { key: "energy_letter", optional: true, rules: [Rules.isString] },
            { key: "nb_bedrooms", optional: true, rules: [Rules.isNumber] },
            { key: "heating_type", optional: true, rules: [Rules.isString] },
            { key: "product_status", rules: [Rules.isString] },
            { key: "media", rules: [Rules.isBoolean] },
            { key: "product_type", rules: [Rules.isString] },
            { key: "product_geolocation", optional: true, rules: [Rules.isString] },
            { key: "tenant_file", optional: true, rules: [Rules.isInArray(["yes", "no", "undefined"])] },
          ],
        },
      ],
    },
  ],
}

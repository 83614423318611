import { DefinitionsViewInterface, LeadResultsInterface, DefinitionsPropsInterface } from "@Interface"
import { testProp } from "@CheckEvent"
import { displayLeadResults } from "@Src/display"

export const testView = (data: { [key: string]: any }, definitionsView: DefinitionsViewInterface) => {
  const leadsResults: LeadResultsInterface = {}

  definitionsView.props.forEach((prop: DefinitionsPropsInterface) => (leadsResults[prop.key] = testProp(data, prop)))

  displayLeadResults(leadsResults)
}

import * as Rules from "@Rules"
import { DefinitionsInterface } from "@Interface"
import { mergeProps } from "@CheckEvent"

const product = [
  {
    key: "products",
    values: [
      { key: "client_id", rules: [Rules.isNumber] },
      { key: "client_postalcode", rules: [Rules.isString] },
      { key: "client_type", rules: [Rules.isString] },
      { key: "country", rules: [Rules.isString] },
      { key: "distribution_type", rules: [Rules.isNumber] },
      { key: "estate_postalcode", rules: [Rules.isString] },
      { key: "estate_type", rules: [Rules.isNumber] },
      { key: "id", rules: [Rules.isNumber] },
      { key: "list_name", rules: [Rules.isString] },
      { key: "media", rules: [Rules.isBoolean] },
      { key: "parent_site", rules: [Rules.isString] },
      { key: "photos_nb", rules: [Rules.isNumber] },
      { key: "position", rules: [Rules.isNumber] },
      { key: "price", rules: [Rules.isNumber] },
      { key: "product_status", rules: [Rules.isString] },
      { key: "product_type", rules: [Rules.isString] },
      { key: "space", rules: [Rules.isNumber] },
      { key: "business_type", optional: true, rules: [Rules.isString] },
      { key: "energy_letter", optional: true, rules: [Rules.isString] },
      { key: "estate_department", optional: true, rules: [Rules.isNumber] },
      { key: "direct_availability", optional: true, rules: [Rules.isBoolean] },
      { key: "floor_nb", optional: true, rules: [Rules.isNumber] },
      { key: "heating_type", optional: true, rules: [Rules.isString] },
      { key: "idbandeau", optional: true, rules: [Rules.isNumber] },
      { key: "investment_type", optional: true, rules: [Rules.isString] },
      { key: "nb_bedrooms", optional: true, rules: [Rules.isNumber] },
      { key: "nb_rooms", optional: true, rules: [Rules.isNumber] },
      { key: "product_geolocation", optional: true, rules: [Rules.isString] },
      { key: "year_of_construction", optional: true, rules: [Rules.isNumber] },
      { key: "valid_criteria", optional: true, rules: [Rules.isNumber] },
      { key: "tenant_file", optional: true, rules: [Rules.isInArray(["yes", "no", "undefined"])] },
    ],
  },
]

export const SLBC: DefinitionsInterface = {
  view: {
    event: "view",
    props: [
      { key: "site", rules: [Rules.equal("SLBC")] },
      {
        key: "search",
        missing: [Rules.isNotSearchPage],
        missingArg: "pagename",
        optional: [Rules.isNotSearchPage],
        optionalArg: "pagename",
        values: [
          { key: "advanced_criteria", optional: [Rules.isSearchClientPage], rules: [Rules.isNumber] },
          { key: "advanced_criteria_business_type", optional: true, rules: [Rules.isString] },
          { key: "advanced_criteria_distribution_type", optional: true, rules: [Rules.isString] },
          { key: "business_type", optional: true, rules: [Rules.isString] },
          { key: "buy_price_max", optional: true, rules: [Rules.isNumber] },
          { key: "buy_price_min", optional: true, rules: [Rules.isNumber] },
          { key: "estate_space_max", rules: [Rules.isNumber] },
          { key: "estate_space_min", rules: [Rules.isNumber] },
          { key: "distribution_type", optional: [Rules.isSearchClientPage], optionalArg: "pagename", rules: [Rules.isNumber] },
          { key: "lease_price_max", optional: true, rules: [Rules.isNumber] },
          { key: "lease_price_min", optional: true, rules: [Rules.isNumber] },
          { key: "location", rules: [Rules.isString] },
          { key: "page_nb", rules: [Rules.isNumber] },
          { key: "refine_type", rules: [Rules.isString] },
          { key: "results_nb", rules: [Rules.isNumber] },
          { key: "search_type", rules: [Rules.isString] },
          { key: "sorting", rules: [Rules.isString] },
          { key: "unit_price", optional: true, rules: [Rules.isString] },
          { key: "media", optional: true, rules: [Rules.isBoolean] },
          { key: "client_postalcode", optional: true, rules: [Rules.isString] },
          {
            key: "location",
            values: [
              {
                key: "postalcode",
                optional: true,
                optionalArg: "search.location.department",
                rules: [Rules.isStringNumber],
              },
            ],
          },
        ],
      },
      {
        key: "products",
        optional: [Rules.isNotSearchPage, Rules.isNotDetailPage],
        optionalArg: "pagename",
        values: [
          { key: "estate_department", optional: true, rules: [Rules.isString] },
          { key: "direct_availability", optional: true, rules: [Rules.isBoolean] },
          { key: "id_rcu", rules: [Rules.isNumber] },
          { key: "idagence", rules: [Rules.isNumber] },
          { key: "idtiers", rules: [Rules.isNumber] },
          { key: "space-coworking_type", optional: true, rules: [Rules.isString] },
          { key: "traffic_flow", optional: true, rules: [Rules.isString] },
          { key: "trafficflow", optional: true, rules: [Rules.isString] },
          { key: "valid_criteria", optional: true, rules: [Rules.isNumber] },
        ],
      },
    ],
  },
  interactions: [
    {
      event: "interaction",
      int_type: "phone",
      int_description: ["conversion"],
      props: mergeProps(product, [{ key: "int_infos", rules: [Rules.isNotEmptyString] }]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["interest"],
      props: mergeProps(product, [{ key: "int_infos", rules: [Rules.isNotEmptyString] }]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["conversion", "confirmation"],
      props: mergeProps(product, [
        { key: "int_infos", rules: [Rules.isNotEmptyString] },
        { key: "form_profile", optional: true, rules: [Rules.isInArray(["buyer", "seller"])] },
        { key: "email_lead", optional: [Rules.match(/^detail_client.*/)], optionalArg: "int_infos", rules: [Rules.isMd5] },
      ]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["opportunity_buyer", "opportunity_buyer_submit", "opportunity_seller_submit"],
      props: mergeProps(product, [
        { key: "int_infos", rules: [Rules.isNotEmptyString] },
        { key: "form_profile", optional: true, rules: [Rules.isInArray(["buyer", "seller"])] },
        { key: "email_lead", optional: [Rules.match(/^detail_client.*/)], optionalArg: "int_infos", rules: [Rules.isMd5] },
      ]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["opportunity_seller_interest"],
      props: mergeProps(product, [{ key: "int_infos", rules: [Rules.isNotEmptyString] }]),
    },
    {
      event: "interaction",
      int_type: "ali",
      int_description: ["interest"],
      props: [
        { key: "int_infos", rules: [Rules.isNotEmptyString] },
        {
          key: "products",
          optional: [Rules.equal("detail-top")],
          optionalArg: "int_infos",
          values: [
            { key: "client_id", rules: [Rules.isNumber] },
            { key: "client_postalcode", rules: [Rules.isString] },
            { key: "client_type", rules: [Rules.isString] },
            { key: "country", rules: [Rules.isString] },
            { key: "direct_availability", rules: [Rules.isBoolean] },
            { key: "distribution_type", rules: [Rules.isNumber] },
            { key: "estate_postalcode", rules: [Rules.isString] },
            { key: "estate_type", rules: [Rules.isNumber] },
            { key: "id", rules: [Rules.isNumber] },
            { key: "list_name", rules: [Rules.isString] },
            { key: "media", rules: [Rules.isBoolean] },
            { key: "parent_site", rules: [Rules.isString] },
            { key: "photos_nb", rules: [Rules.isNumber] },
            { key: "position", rules: [Rules.isNumber] },
            { key: "price", rules: [Rules.isNumber] },
            { key: "product_status", rules: [Rules.isString] },
            { key: "product_type", rules: [Rules.isString] },
            { key: "space", rules: [Rules.isNumber] },
            { key: "valid_criteria", rules: [Rules.isNumber] },
            { key: "trafficflow", optional: true, rules: [Rules.isBoolean] },
          ],
        },
      ],
    },
    {
      event: "interaction",
      int_type: "ali",
      int_description: ["subscription"],
      props: [
        { key: "int_infos", rules: [Rules.isNotEmptyString] },
        { key: "email_lead", rules: [Rules.isMd5] },
        {
          key: "products",
          optional: [Rules.match(/^detail-manual-.*$|^detail-auto$/)],
          optionalArg: "int_infos",
          values: [
            { key: "client_id", rules: [Rules.isNumber] },
            { key: "client_postalcode", rules: [Rules.isString] },
            { key: "client_type", rules: [Rules.isString] },
            { key: "country", rules: [Rules.isString] },
            { key: "direct_availability", rules: [Rules.isBoolean] },
            { key: "distribution_type", rules: [Rules.isNumber] },
            { key: "estate_postalcode", rules: [Rules.isString] },
            { key: "estate_type", rules: [Rules.isNumber] },
            { key: "id", rules: [Rules.isNumber] },
            { key: "list_name", rules: [Rules.isString] },
            { key: "media", rules: [Rules.isBoolean] },
            { key: "parent_site", rules: [Rules.isString] },
            { key: "photos_nb", rules: [Rules.isNumber] },
            { key: "position", rules: [Rules.isNumber] },
            { key: "price", rules: [Rules.isNumber] },
            { key: "product_status", rules: [Rules.isString] },
            { key: "product_type", rules: [Rules.isString] },
            { key: "space", rules: [Rules.isNumber] },
            { key: "valid_criteria", rules: [Rules.isNumber] },
            { key: "trafficflow", optional: true, rules: [Rules.isBoolean] },
          ],
        },
      ],
    },
    {
      event: "interaction",
      int_type: "product",
      int_description: ["click"],
      props: mergeProps(product, [{ key: "int_infos", rules: [Rules.isNotEmptyString] }]),
    },
    {
      event: "interaction",
      int_type: "form-error",
      int_description: /^.{1,}$/,
      props: mergeProps(product, [{ key: "int_infos", rules: [Rules.isNotEmptyString] }]),
    },
    {
      event: "interaction",
      int_type: "navigation",
      int_description: ["click"],
      props: mergeProps(product, [{ key: "int_infos", rules: [Rules.isNotEmptyString] }]),
    },
    /*
    {
      event: "interaction",
      int_type: "product",
      int_description: ["impression"],
      props: mergeProps( product,
        [
          { key: "int_infos", rules: [ Rules.equalByDependancy ], optionalArg: 'pagename', haveDependancy: true }, 
        ]
      )
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["boost"],
      props: [
        { key: "int_infos", rules: [ Rules.isInArray(["detail-get_plan", "detail-brochure_top", "detail-brochure_bottom", "detail-right", "detail-bottom",
            "detail-promoteur", "detail-post_favorite", "detail-caroussel", "detail-notaire", "detail-more", "detail-saving", "detail-rental",
            "detail-delivery", "detail-financement", "search_results-post_favorite", "post_lead-phone-right", "post_lead-phone-bottom",
            "post_lead-phone-promoteur", "detail_client-post_lead-phone-right"]) ]
        }
      ]
    },
    {
      event: "interaction",
      int_type: "favorite",
      int_description: ["add", "remove"],
      props: mergeProps( product,
        [
          { key: "int_infos", rules: [ Rules.isInArray([ "detail-top", "detail", "search_results", "detail_client", "homepage", "account-favorites" ]) ]
          }
        ]
      )
    },*/
  ],
}

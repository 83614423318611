import { DefinitionsLeadInterface } from "@Interface"

export const mergeInteractions = (common: DefinitionsLeadInterface[], site: DefinitionsLeadInterface[]) => {
  let merge: DefinitionsLeadInterface[] = [],
    toMerge: DefinitionsLeadInterface | DefinitionsLeadInterface[]

  common.forEach((lead) => {
    toMerge = site.find((siteLead) => {
      if (siteLead.event === lead.event && siteLead.int_type === lead.int_type) {
        let siteDesc = Array.isArray(siteLead.int_description) ? siteLead.int_description : [siteLead.int_description],
          desc = Array.isArray(lead.int_description) ? lead.int_description : [lead.int_description]

        if (siteDesc.some((sDesc) => desc.includes(sDesc))) {
          let res = { ...lead, ...siteLead }
          return (res.props = siteLead.props)
        }
      }
    })
    merge.push(toMerge || lead)
  })
  toMerge = site.filter(
    (siteInt) =>
      !common.some((commonInt) => {
        let siteDesc = Array.isArray(siteInt.int_description) ? siteInt.int_description : [siteInt.int_description],
          desc = Array.isArray(commonInt.int_description) ? commonInt.int_description : [commonInt.int_description]
        return siteInt.int_type === commonInt.int_type && siteDesc.some((sDesc) => desc.includes(sDesc))
      })
  )
  toMerge.forEach((prop) => merge.push(prop))
  return merge
}

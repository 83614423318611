import * as Rules from "@Rules"
import { DefinitionsInterface } from "@Interface"
import { mergeProps } from "@CheckEvent"

const product = [
  {
    key: "products",
    values: [
      { key: "list_name", rules: [Rules.isString] },
      { key: "position", rules: [Rules.isNumber] },
      { key: "price", rules: [Rules.isNumber] },
      { key: "estate_type", rules: [Rules.isNumber] },
      { key: "distribution_type", rules: [Rules.isNumber] },
      { key: "space", optional: true, rules: [Rules.isNumber] },
      { key: "id", rules: [Rules.isNumber] },
      { key: "client_id", rules: [Rules.isNumber] },
      { key: "photos_nb", rules: [Rules.isNumber] },
      { key: "estate_department", optional: true, rules: [Rules.isNumber] },
      { key: "estate_postalcode", rules: [Rules.isString] },
      { key: "client_postalcode", optional: true, rules: [Rules.isString] },
      { key: "country", rules: [Rules.isString] },
      { key: "client_type", rules: [Rules.isString] },
      { key: "investment_type", rules: [Rules.isString] },
      { key: "business_type", rules: [Rules.isString] },
      { key: "parent_site", rules: [Rules.isString] },
      { key: "year_of_construction", rules: [Rules.isNumber] },
      { key: "floor_nb", optional: true, rules: [Rules.isNumber] },
      { key: "nb_rooms", rules: [Rules.isNumber] },
      { key: "energy_letter", optional: true, rules: [Rules.isString] },
      { key: "nb_bedrooms", optional: true, rules: [Rules.isNumber] },
      { key: "heating_type", optional: true, rules: [Rules.isString] },
      { key: "product_status", rules: [Rules.isString] },
      { key: "media", rules: [Rules.isString] },
      { key: "product_type", rules: [Rules.isString] },
      { key: "product_geolocation", rules: [Rules.isString] },
      { key: "tenant_file", optional: true, rules: [Rules.isInArray(["yes", "no", "undefined"])] },
    ],
  },
]

export const SL: DefinitionsInterface = {
  view: {
    event: "view",
    props: [
      {
        key: "user",
        values: [{ key: "logged", rules: [Rules.isBoolean] }],
      },
      { key: "site", rules: [Rules.equal("SL")] },
      { key: "medium", optional: true, rules: [Rules.isString] },
      { key: "phone_available", optional: true, missing: [Rules.isNotDetailPage], missingArg: "pagename", rules: [Rules.isInArray(["yes", "no"])] },
      {
        key: "tenant_file",
        optional: true,
        missing: [Rules.isNotDetailPage],
        missingArg: "pagename",
        rules: [Rules.isInArray(["yes", "no", "undefined"])],
      },
      {
        key: "search",
        missing: [Rules.isNotSearchPage],
        missingArg: "pagename",
        optional: [Rules.isNotSearchPage],
        optionalArg: "pagename",
        values: [
          { key: "room_nb", optional: true, rules: [Rules.isNumber, Rules.isStringNumber, Rules.isUndefined] },
          { key: "client_type", rules: [Rules.isString] },
          { key: "advanced_criteria_purchase_type", optional: true, rules: [Rules.isString] },
          { key: "stricted_search", optional: true, rules: [Rules.isBoolean] },
        ],
      },
      {
        key: "products",
        optional: [Rules.isNotSearchPage, Rules.isNotDetailPage],
        optionalArg: "pagename",
        values: [
          { key: "estate_type", rules: [Rules.isNumber] },
          { key: "distribution_type", rules: [Rules.isNumber] },
          { key: "space", optional: true, rules: [Rules.isNumber] },
          { key: "estate_department", optional: true, rules: [Rules.isNumber] },
          { key: "client_postalcode", optional: true, rules: [Rules.isString] },
          { key: "investment_type", rules: [Rules.isString] },
          { key: "business_type", rules: [Rules.isString] },
          { key: "floor_nb", optional: true, rules: [Rules.isNumber] },
          { key: "nb_rooms", rules: [Rules.isNumber, Rules.isStringNumber] },
          { key: "energy_letter", optional: true, rules: [Rules.isString] },
          { key: "nb_bedrooms", optional: true, rules: [Rules.isNumber, Rules.isStringNumber] },
          { key: "heating_type", optional: true, rules: [Rules.isString] },
          { key: "media", rules: [Rules.isString] },
          { key: "product_geolocation", rules: [Rules.isString] },
          { key: "tenant_file", optional: true, rules: [Rules.isInArray(["yes", "no", "undefined"])] },
        ],
      },
    ],
  },
  interactions: [
    {
      event: "interaction",
      int_type: "phone",
      int_description: ["conversion"],
      props: mergeProps(product, [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray([
              "detail-right",
              "detail-sticky",
              "detail-modale",
              "detail-responsive",
              "detail-agency_block",
              "detail-caroussel",
              "detail_client-right",
              "detail_client-responsive",
            ]),
          ],
        },
      ]),
    },
    {
      event: "interaction",
      int_type: "phone",
      int_description: ["phone_call-initiated"],
      props: mergeProps(product, [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray(["detail-right", "detail-sticky", "detail-modale", "detail-responsive", "detail-agency_block", "detail-caroussel"]),
          ],
        },
      ]),
    },
    {
      event: "interaction",
      int_type: "phone",
      int_description: ["phone_call-answered"],
      props: mergeProps(product, [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray(["detail-right", "detail-sticky", "detail-modale", "detail-responsive", "detail-agency_block", "detail-caroussel"]),
          ],
        },
      ]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["interest"],
      props: mergeProps(product, [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray([
              "detail-sticky",
              "detail-agency_block",
              "detail-responsive",
              "detail-post_contact",
              "detail-get_adress",
              "detail-get_infos",
              "detail_client-get_selling_price",
              "detail_client-responsive",
            ]),
          ],
        },
      ]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["conversion", "confirmation"],
      props: mergeProps(product, [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray([
              "detail-right",
              "detail-bottom",
              "detail-modale",
              "detail-post_contact",
              "detail_client-right",
              "detail_client-responsive",
              "detail_client-get_selling_price",
            ]),
          ],
        },
        { key: "form_profile", optional: true, rules: [Rules.equal("seller")] },
        { key: "email_lead", optional: [Rules.match(/^detail_client.*/)], optionalArg: "int_infos", rules: [Rules.isMd5] },
      ]),
    },
    {
      event: "interaction",
      int_type: "ali",
      int_description: ["interest"],
      props: [
        { key: "int_infos", rules: [Rules.isNotEmptyString] },
        {
          key: "products",
          optional: [Rules.equal("detail-top")],
          optionalArg: "int_infos",
          values: [
            { key: "list_name", rules: [Rules.isString] },
            { key: "position", rules: [Rules.isNumber] },
            { key: "price", rules: [Rules.isNumber] },
            { key: "estate_type", rules: [Rules.isNumber] },
            { key: "distribution_type", rules: [Rules.isNumber] },
            { key: "space", optional: true, rules: [Rules.isNumber] },
            { key: "id", rules: [Rules.isNumber] },
            { key: "client_id", rules: [Rules.isNumber] },
            { key: "photos_nb", rules: [Rules.isNumber] },
            { key: "estate_department", optional: true, rules: [Rules.isNumber] },
            { key: "estate_postalcode", rules: [Rules.isString] },
            { key: "client_postalcode", optional: true, rules: [Rules.isString] },
            { key: "country", rules: [Rules.isString] },
            { key: "client_type", rules: [Rules.isString] },
            { key: "investment_type", rules: [Rules.isString] },
            { key: "business_type", rules: [Rules.isString] },
            { key: "parent_site", rules: [Rules.isString] },
            { key: "year_of_construction", rules: [Rules.isNumber] },
            { key: "floor_nb", optional: true, rules: [Rules.isNumber] },
            { key: "nb_rooms", rules: [Rules.isNumber] },
            { key: "energy_letter", rules: [Rules.isString] },
            { key: "nb_bedrooms", optional: true, rules: [Rules.isNumber] },
            { key: "heating_type", optional: true, rules: [Rules.isString] },
            { key: "product_status", rules: [Rules.isString] },
            { key: "media", rules: [Rules.isString] },
            { key: "product_type", rules: [Rules.isString] },
            { key: "product_geolocation", rules: [Rules.isString] },
            { key: "tenant_file", optional: true, rules: [Rules.isInArray(["yes", "no", "undefined"])] },
          ],
        },
      ],
    },
    {
      event: "interaction",
      int_type: "ali",
      int_description: ["subscription"],
      props: [
        { key: "int_infos", rules: [Rules.isNotEmptyString] },
        { key: "email_lead", rules: [Rules.isMd5] },
        {
          key: "products",
          optional: [Rules.match(/^detail-manual-.*$|^detail-auto$/)],
          optionalArg: "int_infos",
          values: [
            { key: "list_name", rules: [Rules.isString] },
            { key: "position", rules: [Rules.isNumber] },
            { key: "price", rules: [Rules.isNumber] },
            { key: "estate_type", rules: [Rules.isNumber] },
            { key: "distribution_type", rules: [Rules.isNumber] },
            { key: "space", optional: true, rules: [Rules.isNumber] },
            { key: "id", rules: [Rules.isNumber] },
            { key: "client_id", rules: [Rules.isNumber] },
            { key: "photos_nb", rules: [Rules.isNumber] },
            { key: "estate_department", optional: true, rules: [Rules.isNumber] },
            { key: "estate_postalcode", rules: [Rules.isString] },
            { key: "client_postalcode", optional: true, rules: [Rules.isString] },
            { key: "country", rules: [Rules.isString] },
            { key: "client_type", rules: [Rules.isString] },
            { key: "investment_type", rules: [Rules.isString] },
            { key: "business_type", rules: [Rules.isString] },
            { key: "parent_site", rules: [Rules.isString] },
            { key: "year_of_construction", rules: [Rules.isNumber] },
            { key: "floor_nb", optional: true, rules: [Rules.isNumber] },
            { key: "nb_rooms", rules: [Rules.isNumber] },
            { key: "energy_letter", rules: [Rules.isString] },
            { key: "nb_bedrooms", optional: true, rules: [Rules.isNumber] },
            { key: "heating_type", optional: true, rules: [Rules.isString] },
            { key: "product_status", rules: [Rules.isString] },
            { key: "media", rules: [Rules.isString] },
            { key: "product_type", rules: [Rules.isString] },
            { key: "product_geolocation", rules: [Rules.isString] },
            { key: "tenant_file", optional: true, rules: [Rules.isInArray(["yes", "no", "undefined"])] },
          ],
        },
      ],
    },
    {
      event: "interaction",
      int_type: "product",
      int_description: ["click"],
      props: mergeProps(product, [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray([
              "detail-similars_bottom",
              "search_results",
              "search_results_map",
              "homepage",
              "recommendation",
              "account-favorites",
              "account-saved_search",
              "account-last_seen",
              "detail_client-estate",
              "search_result_client",
            ]),
          ],
        },
      ]),
    },
  ],
}

import { DefinitionsPropsInterface, LeadResultsInterface } from "@Interface"
import { RuleInterface } from "@Rules"
import { getValue } from "@CheckEvent"

export const testProp = (data: { [key: string]: any }, prop: DefinitionsPropsInterface, globalDatas?: { [key: string]: any }): any => {
  const leadsResults: LeadResultsInterface = { message: {} }
  let optional: Boolean,
    missing: Boolean,
    datas: { [key: string]: any } = globalDatas || data

  if (Array.isArray(prop.missing)) {
    missing = prop.missing.every((rule: RuleInterface) => {
      if (!!prop.haveDependancy) {
        return rule(data[prop.key], getValue(datas, prop.missingArg)).valid
      } else {
        return rule(prop.missingArg ? getValue(datas, prop.missingArg) : data[prop.key]).valid
      }
    })
  } else {
    missing = prop.missing
  }
  if (Array.isArray(prop.optional)) {
    optional = prop.optional.every((rule: RuleInterface) => {
      if (!!prop.haveDependancy) {
        return rule(data[prop.key], getValue(datas, prop.optionalArg)).valid
      } else {
        return rule(prop.optionalArg ? getValue(datas, prop.optionalArg) : data[prop.key]).valid
      }
    })
  } else {
    optional = prop.optional
  }

  if (missing && prop.key in data) {
    leadsResults.message.error = `"${prop.key}" must not be defined for this event`
  }
  if (!optional && typeof data[prop.key] === "undefined" && !["event", "int_description", "int_type"].includes(prop.key)) {
    leadsResults.message.error = `"${prop.key}" is missing`
  }

  if (!!prop.values && prop.values.length && !optional) {
    prop.values.forEach((prop_: DefinitionsPropsInterface) => {
      if (Array.isArray(data[prop.key])) {
        leadsResults.childs = leadsResults.childs || {}
        let i = 0
        data[prop.key].forEach((elt: any, id: number) => {
          leadsResults.childs[id] = {}
          leadsResults.childs[id].childs = {}
          leadsResults.childs[id].childs[prop_.key] = testProp(elt, prop_, datas)
          i++
        })
      } else {
        leadsResults.childs = leadsResults.childs || {}
        leadsResults.childs[prop_.key] = testProp(data[prop.key] || {}, prop_, datas)
      }
    })
  } else if (typeof data[prop.key] !== "undefined" && !!prop.rules && prop.rules.length) {
    let msg: string,
      passed = prop.rules.some((rule: RuleInterface) => {
        if (!!!rule(data[prop.key]).valid) {
          if (!optional || (optional && data[prop.key] !== null && data[prop.key] !== "undefined" && data[prop.key] !== "")) {
            !!msg
              ? (msg += ` OR ${
                  !!prop.haveDependancy ? rule(data[prop.key], getValue(datas, prop.optionalArg)).message : rule(data[prop.key]).message
                }`)
              : (msg = `"${prop.key}" : ${
                  !!prop.haveDependancy ? rule(data[prop.key], getValue(datas, prop.optionalArg)).message : rule(data[prop.key]).message
                }`)
          }
          return false
        }
        return true
      })
    if (!passed) {
      !!leadsResults.message.error ? (leadsResults.message.error += msg) : (leadsResults.message.error = msg)
    }
  }

  return leadsResults
}

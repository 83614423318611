import * as Rules from "@Rules"
import { DefinitionsInterface } from "@Interface"
import { mergeProps } from "@CheckEvent"

const product = [
  {
    key: "products",
    values: [
      { key: "list_name", rules: [Rules.isString] },
      { key: "position", rules: [Rules.isNumber] },
      { key: "price", rules: [Rules.isNumber] },
      { key: "estate_type", rules: [Rules.isNumber] },
      { key: "distribution_type", rules: [Rules.isNumber] },
      { key: "space", optional: true, rules: [Rules.isNumber] },
      { key: "id", rules: [Rules.isNumber] },
      { key: "client_id", rules: [Rules.isNumber] },
      { key: "photos_nb", rules: [Rules.isNumber] },
      { key: "estate_department", optional: true, rules: [Rules.isNumber] },
      { key: "estate_postalcode", rules: [Rules.isString] },
      { key: "client_postalcode", optional: true, rules: [Rules.isStringNumber] },
      { key: "country", rules: [Rules.isString] },
      { key: "idbandeau", rules: [Rules.isNumber] },
      { key: "client_type", rules: [Rules.isString] },
      { key: "investment_type", rules: [Rules.isString] },
      { key: "business_type", rules: [Rules.isString] },
      { key: "parent_site", rules: [Rules.isString] },
      { key: "year_of_construction", rules: [Rules.isNumber] },
      { key: "floor_nb", optional: true, rules: [Rules.isNumber] },
      { key: "nb_rooms", rules: [Rules.isNumber] },
      { key: "energy_letter", optional: true, rules: [Rules.isString] },
      { key: "nb_bedrooms", optional: true, rules: [Rules.isNumber] },
      { key: "heating_type", optional: true, rules: [Rules.isString] },
      { key: "product_status", rules: [Rules.isString] },
      { key: "media", rules: [Rules.isBoolean] },
      { key: "product_type", rules: [Rules.isString] },
      { key: "product_geolocation", rules: [Rules.isString] },
      { key: "id_solo", rules: [Rules.isNumber] },
      { key: "id_duo", rules: [Rules.isNumber] },
      { key: "media", rules: [Rules.isNumber] },
    ],
  },
]

export const LICOM: DefinitionsInterface = {
  view: {
    event: "view",
    props: [
      { key: "site", rules: [Rules.equal("LICOM")] },
      {
        key: "products",
        values: [
          { key: "id_solo", rules: [Rules.isNumber] },
          { key: "id_duo", rules: [Rules.isNumber] },
          { key: "media", rules: [Rules.isNumber] },
          { key: "client_postalcode", rules: [Rules.isStringNumber] },
        ],
      },
      {
        key: "search",
        missing: [Rules.isNotSearchPage],
        missingArg: "pagename",
        optional: [Rules.isNotSearchPage],
        optionalArg: "pagename",
        values: [
          { key: "distribution_type", optional: [Rules.isSearchClientPage], optionalArg: "pagename", rules: [Rules.isNumber] },
          { key: "advanced_criteria", optional: true, rules: [Rules.isNumber] },
          { key: "estate_plot_min", optional: true, rules: [Rules.isStringNumber] },
          { key: "estate_plot_max", optional: true, rules: [Rules.isStringNumber] },
        ],
      },
    ],
  },
  interactions: [
    {
      event: "interaction",
      int_type: "product",
      int_description: ["click"],
      props: mergeProps(product, [{ key: "int_infos", rules: [Rules.isNotEmptyString] }]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["interest"],
      props: mergeProps(product, [{ key: "int_infos", rules: [Rules.isNotEmptyString] }]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["conversion", "confirmation"],
      props: mergeProps(product, [
        { key: "int_infos", rules: [Rules.isNotEmptyString] },
        { key: "email_lead", rules: [Rules.isMd5] },
      ]),
    },
    {
      event: "interaction",
      int_type: "phone",
      int_description: ["conversion"],
      props: mergeProps(product, [{ key: "int_infos", rules: [Rules.isNotEmptyString] }]),
    },
    {
      event: "interaction",
      int_type: "ali",
      int_description: ["interest"],
      props: [{ key: "int_infos", rules: [Rules.isNotEmptyString] }],
    },
    {
      event: "interaction",
      int_type: "ali",
      int_description: ["subscription"],
      props: [
        { key: "int_infos", rules: [Rules.isNotEmptyString] },
        { key: "email_lead", rules: [Rules.isMd5] },
      ],
    },
  ],
}

import { DefinitionsPropsInterface, LeadResultsInterface } from "@Interface"

const findInProps = function (props: DefinitionsPropsInterface[], key: String) {
  return props.some((prop) => {
    let res = prop?.key === key
    if (!res) {
      if (!!prop.values) {
        res = findInProps(prop.values, key)
      }
    }
    return res
  })
}

export const testData = (data: { [key: string]: any }, props: DefinitionsPropsInterface[]): any => {
  const unknowns: LeadResultsInterface = { message: {} }

  for (let key in data) {
    if (key === "campaign" || key === "levier_BD") {
      continue
    }
    if (Array.isArray(data[key])) {
      unknowns.childs = {}
      let i = 0
      data[key].forEach((elt: any, id: number) => {
        unknowns.childs[id] = {}
        unknowns.childs[id].childs = {}
        unknowns.childs[id].childs["dummy" + i] = testData(elt, props)
        i++
      })
    } else if (typeof data[key] === "object") {
      unknowns.childs = {}
      for (let subK in data[key]) {
        unknowns.childs[subK] = testData({ [subK]: data[key][subK] }, props)
      }
    } else {
      if (!findInProps(props, key)) {
        !!unknowns.message?.warning
          ? (unknowns.message.warning += `\n         "${key}" is not defined in the current model`)
          : (unknowns.message.warning = `"${key}" is not defined in the current model`)
      }
    }
  }
  return unknowns
}

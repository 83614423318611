export const getValue = (obj: Object, str: String) => {
  let ids: Array<string>,
    res: object = obj

  str = str.replace(/\[(\w+)\]/g, ".$1")
  str = str.replace(/^\./, "")
  ids = str.split(".")

  for (let i: number = 0, n: number = ids.length; i < n; ++i) {
    let key: string = ids[i]

    if (key in res) {
      res = res[key]
    } else {
      return
    }
  }
  return res
}

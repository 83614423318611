import * as Rules from "@Rules"
import { DefinitionsInterface, DefinitionsPropsInterface } from "@Interface"
import { mergeProps } from "@CheckEvent"

const interactionDefault = [
  { key: "int_infos", rules: [Rules.isString] },
  { key: "nievent", optional: true, rules: [Rules.isBoolean] },
  {
    key: "products",
    values: [
      { key: "list_name", rules: [Rules.isString] },
      { key: "position", rules: [Rules.isNumber] },
      { key: "price", rules: [Rules.isNumber] },
      { key: "estate_type", rules: [Rules.isNumber] },
      { key: "distribution_type", rules: [Rules.isNumber] },
      { key: "space", rules: [Rules.isNumber] },
      { key: "id", rules: [Rules.isNumber] },
      { key: "client_id", rules: [Rules.isNumber] },
      { key: "photos_nb", rules: [Rules.isNumber] },
      { key: "estate_postalcode", rules: [Rules.isString] },
      { key: "client_postalcode", rules: [Rules.isString] },
      { key: "country", rules: [Rules.isString] },
      { key: "banner_id", optional: true, rules: [Rules.isNumber] },
      { key: "client_type", rules: [Rules.isInArray(["pro", "private"])] },
      { key: "investment_type", rules: [Rules.isString] },
      { key: "business_type", rules: [Rules.isString] },
      { key: "parent_site", rules: [Rules.isString] },
      { key: "year_of_construction", rules: [Rules.isNumber] },
      { key: "floor_nb", rules: [Rules.isNumber] },
      { key: "nb_rooms", rules: [Rules.isNumber] },
      { key: "nb_bedrooms", rules: [Rules.isNumber] },
      { key: "heating_type", rules: [Rules.isString] },
      { key: "energy_letter", rules: [Rules.isString] },
      { key: "product_status", rules: [Rules.isString] },
      { key: "product_type", rules: [Rules.isString] },
      { key: "media", rules: [Rules.isBoolean] },
    ],
  },
]

export const common: DefinitionsInterface = {
  view: {
    event: "view",
    props: [
      {
        key: "user",
        values: [
          { key: "email_lead", optional: true, rules: [Rules.isMd5] },
          { key: "email_crm", optional: true, rules: [Rules.isMd5] },
          { key: "email_account", optional: true, rules: [Rules.isMd5] },
          { key: "logged", optional: true, rules: [Rules.isBoolean] },
          { key: "user_id", optional: true, rules: [Rules.isNumber] },
          { key: "optin_ali", optional: true, rules: [Rules.isBoolean] }, //imported datas?
        ],
      },
      { key: "site", rules: [Rules.isNotEmptyString] },
      { key: "pagename", rules: [Rules.isNotEmptyString] },
      { key: "language", rules: [Rules.isString] },
      { key: "abtest", optional: true, rules: [Rules.isString, Rules.isNumber] },
      { key: "abtest-2", optional: true, rules: [Rules.isString, Rules.isNumber] },
      { key: "abtest-3", optional: true, rules: [Rules.isString, Rules.isNumber] },
      { key: "environment", rules: [Rules.isNotEmptyString] },
      { key: "url_path", optional: true, rules: [Rules.isString] }, //mandatory if react
      { key: "referrer", optional: true, rules: [Rules.isString] }, //mandatory if react
      { key: "form_profile", optional: true, rules: [Rules.isString] },
      { key: "form_profile_precision", optional: true, rules: [Rules.isString] },
      { key: "form_postalcode", optional: true, rules: [Rules.isString] },
      { key: "form_estate_type", optional: true, rules: [Rules.isString] },
      { key: "form_space", optional: true, rules: [Rules.isNumber] },
      { key: "form_qualified_profile", optional: true, rules: [Rules.isString] },
      { key: "error_code", optional: true, rules: [Rules.isString] },
      { key: "temp_variable_1", optional: true, rules: [Rules.isString] },
      { key: "temp_variable_2", optional: true, rules: [Rules.isString] },
      {
        key: "search",
        missing: [Rules.isNotSearchPage],
        missingArg: "pagename",
        optional: [Rules.isNotSearchPage],
        optionalArg: "pagename",
        values: [
          { key: "price_min", optional: true, rules: [Rules.isNumber] },
          { key: "price_max", optional: true, rules: [Rules.isNumber] },
          { key: "estate_space_min", optional: true, rules: [Rules.isNumber] },
          { key: "estate_space_max", optional: true, rules: [Rules.isNumber] },
          { key: "estate_plot_min", optional: true, rules: [Rules.isNumber] },
          { key: "estate_plot_max", optional: true, rules: [Rules.isNumber] },
          { key: "estate_type", optional: [Rules.isSearchClientPage], optionalArg: "pagename", rules: [Rules.isNotEmptyString] },
          { key: "distribution_type", optional: [Rules.isSearchClientPage], optionalArg: "pagename", rules: [Rules.isNotEmptyString] },
          { key: "investment_type", optional: true, rules: [Rules.isString] },
          { key: "business_type", optional: true, rules: [Rules.isString] },
          {
            key: "location",
            values: [
              { key: "country", optional: true, rules: [Rules.isString] },
              { key: "region", optional: true, rules: [Rules.isString] },
              { key: "department", optional: true, rules: [Rules.isStringNumber] },
              { key: "city", optional: true, rules: [Rules.isString] },
              { key: "district", optional: true, rules: [Rules.isString] },
              {
                key: "postalcode",
                optional: [Rules.isNotEmptyString],
                optionalArg: "search.location.department",
                rules: [Rules.isStringNumber],
              },
              { key: "location_type", rules: [Rules.isString] },
              { key: "location_keywords", rules: [Rules.isString] },
            ],
          },
          { key: "date_start", optional: [Rules.isNotRentSite], optionalArg: "site" },
          { key: "date_end", optional: [Rules.isNotRentSite], optionalArg: "site" },
          { key: "sorting", rules: [Rules.isString] },
          { key: "advanced_criteria", optional: true, rules: [Rules.isString] },
          { key: "advanced_criteria_purchase_type", optional: true, rules: [Rules.isString] },
          { key: "results_nb", rules: [Rules.isNumber] },
          { key: "page_nb", rules: [Rules.isNumber] },
          { key: "room_nb", optional: true, rules: [Rules.isNumber, Rules.isUndefined] },
          { key: "bedroom_nb", optional: true, rules: [Rules.isNumber, Rules.isUndefined] },
          { key: "people_nb", optional: true, rules: [Rules.isNumber] },
          { key: "client_type", optional: true, rules: [Rules.isString] },
          { key: "business_type", optional: true, rules: [Rules.isStringNumber] },
          { key: "search_type", optional: true, rules: [Rules.isString] },
          { key: "refine_type", optional: true, rules: [Rules.isString] },
        ],
      },
      {
        key: "products",
        optional: [Rules.isNotSearchPage, Rules.isNotDetailPage],
        optionalArg: "pagename",
        values: [
          { key: "list_name", rules: [Rules.isString] },
          { key: "position", rules: [Rules.isNumber] },
          { key: "price", rules: [Rules.isNumber] },
          { key: "estate_type", rules: [Rules.isStringNumber] },
          { key: "distribution_type", rules: [Rules.isStringNumber] },
          { key: "space", rules: [Rules.isNumber] },
          { key: "id", rules: [Rules.isNumber] },
          { key: "client_id", rules: [Rules.isNumber] },
          { key: "photos_nb", rules: [Rules.isNumber] },
          { key: "estate_department", rules: [Rules.isNumber] },
          { key: "estate_postalcode", rules: [Rules.isString] },
          { key: "country", rules: [Rules.isString] },
          { key: "client_type", rules: [Rules.isString] },
          { key: "business_type", rules: [Rules.isStringNumber] },
          { key: "parent_site", rules: [Rules.isString] },
          { key: "year_of_construction", rules: [Rules.isNumber] },
          { key: "floor_nb", rules: [Rules.isNumber] },
          { key: "nb_rooms", rules: [Rules.isNumber] },
          { key: "nb_bedrooms", rules: [Rules.isNumber] },
          { key: "heating_type", rules: [Rules.isString] },
          { key: "energy_letter", rules: [Rules.isString] },
          { key: "product_status", rules: [Rules.isString] },
          { key: "product_type", rules: [Rules.isString] },
        ],
      },
    ],
  },
  interactions: [
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["interest"],
      props: interactionDefault,
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["conversion", "confirmation"],
      props: mergeProps(interactionDefault, [{ key: "user", values: [{ key: "email_lead", rules: [Rules.isMd5] }] }]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["mandat", "preneur", "seller"],
      props: [
        { key: "int_infos", rules: [Rules.isNumber] },
        { key: "user", values: [{ key: "email_lead", rules: [Rules.isMd5] }] },
      ],
    },
    {
      event: "interaction",
      int_type: "phone",
      int_description: ["interest-snapcall", "conversion", "confirmation"],
      props: interactionDefault,
    },
    {
      event: "interaction",
      int_type: "ali",
      int_description: ["interest"],
      props: [{ key: "int_infos", rules: [Rules.isNumber] }],
    },
    {
      event: "interaction",
      int_type: "ali",
      int_description: ["subcription", "unsubscrition"],
      props: [
        { key: "int_infos", rules: [Rules.isString] },
        { key: "user", values: [{ key: "email_lead", rules: [Rules.isMd5] }] },
      ],
    },
    {
      event: "interaction",
      int_type: "favorite",
      int_description: ["add", "remove"],
      props: interactionDefault,
    },
    {
      event: "interaction",
      int_type: "share",
      int_description: ["print", "mail", "social"],
      props: mergeProps(interactionDefault, [{ key: "int_infos", rules: [Rules.equal("detail")] }]),
    },
    {
      event: "interaction",
      int_type: "product",
      int_description: ["click", "impression"],
      props: interactionDefault,
    },
    {
      event: "interaction",
      int_type: "sign-in",
      int_description: ["regular/social"],
      props: [{ key: "int_infos", rules: [Rules.isString] }],
    },
    {
      event: "interaction",
      int_type: "sign-up",
      int_description: ["regular/social"],
      props: [{ key: "int_infos", rules: [Rules.isString] }],
    },
    {
      event: "interaction",
      int_type: "recommendation",
      int_description: ["keep", "skip"],
      props: mergeProps(interactionDefault, [{ key: "int_infos", rules: [Rules.equal("recommendation")] }]),
    },
    {
      event: "interaction",
      int_type: "recommendation",
      int_description: ["keep", "skip"],
      props: [
        { key: "int_infos", optional: true, rules: [Rules.isString] },
        {
          key: "products",
          values: [
            { key: "list_name", rules: [Rules.equal("recommendation")] },
            { key: "position", rules: [Rules.isNumber] },
            { key: "price", rules: [Rules.isNumber] },
            { key: "estate_type", rules: [Rules.isNumber] },
            { key: "distribution_type", rules: [Rules.isNumber] },
            { key: "space", rules: [Rules.isNumber] },
            { key: "id", rules: [Rules.isNumber] },
            { key: "client_id", rules: [Rules.isNumber] },
            { key: "photos_nb", rules: [Rules.isNumber] },
            { key: "estate_postalcode", rules: [Rules.isString] },
            { key: "client_postalcode", rules: [Rules.isString] },
            { key: "country", rules: [Rules.isString] },
            { key: "banner_id", optional: true, rules: [Rules.isNumber] },
            { key: "client_type", rules: [Rules.isInArray(["pro", "private"])] },
            { key: "investment_type", rules: [Rules.isString] },
            { key: "business_type", rules: [Rules.isString] },
            { key: "parent_site", rules: [Rules.isString] },
            { key: "year_of_construction", rules: [Rules.isNumber] },
            { key: "floor_nb", rules: [Rules.isNumber] },
            { key: "nb_rooms", rules: [Rules.isNumber] },
            { key: "nb_bedrooms", rules: [Rules.isNumber] },
            { key: "heating_type", rules: [Rules.isString] },
            { key: "energy_letter", rules: [Rules.isString] },
            { key: "product_status", rules: [Rules.isString] },
            { key: "product_type", rules: [Rules.isString] },
            { key: "media", rules: [Rules.equal(5)] },
          ],
        },
      ],
    },
    {
      event: "interaction",
      int_type: "navigation",
      int_description: ["click"],
      props: [{ key: "int_infos", rules: [Rules.isString] }],
    },
  ],
}

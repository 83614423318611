import { DefinitionsPropsInterface } from "@Interface"

export const mergeProps = (common: DefinitionsPropsInterface[], custom: DefinitionsPropsInterface[]) => {
  let merge: DefinitionsPropsInterface[] = [],
    toMerge: DefinitionsPropsInterface | DefinitionsPropsInterface[]

  common.forEach((prop) => {
    let tmp = custom.find((customProp) => customProp.key === prop.key) || {}
    toMerge = { ...prop, ...tmp }
    if (!!tmp.values) {
      toMerge.values = mergeProps(prop.values, tmp.values)
    }
    merge.push(toMerge)
  })
  toMerge = custom.filter((obj) => !common.some((item) => item.key === obj.key))
  toMerge.forEach((prop) => merge.push(prop))
  return merge
}

import { RuleResultInterface, RuleInterface, RuleHOFInterface } from "./interface"

export const match: RuleHOFInterface = (match: any): RuleInterface => {
  const fn: RuleInterface = (value: any): RuleResultInterface => {
    return {
      valid: match instanceof RegExp && match.test(value),
      message: `${value} should be a RegExp and match ${match}`,
    }
  }
  return fn
}

import { LeadResultsInterface } from "@Interface"
import { testData } from "@CheckEvent"
import { displayWarningUnknowed } from "@Src/display"

export const showUnknownProp = (data: { [key: string]: any }, definition: any) => {
  const unknowns: LeadResultsInterface = {}

  for (let key in data) {
    if (key !== "event" && key !== "int_description" && key !== "int_type") {
      unknowns[key] = testData({ [key]: data[key] }, definition.props)
    }
  }
  displayWarningUnknowed(unknowns)
}

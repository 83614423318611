import { RuleResultInterface, RuleInterface, RuleHOFInterface } from "./interface"

export const isInArray: RuleHOFInterface = (matches: Array<any>): RuleInterface => {
  const fn: RuleInterface = (value: any): RuleResultInterface => {
    return {
      valid: Array.isArray(matches) && matches.includes(value),
      message: `${value} not in ${matches} array`,
    }
  }
  return fn
}

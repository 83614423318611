import * as Rules from "@Rules"
import { DefinitionsInterface } from "@Interface"
import { mergeProps } from "@CheckEvent"

const product = [
  {
    key: "products",
    values: [
      { key: "list_name", rules: [Rules.isString] },
      { key: "position", rules: [Rules.isNumber] },
      { key: "price", rules: [Rules.isNumber] },
      { key: "estate_type", rules: [Rules.isNumber] },
      { key: "distribution_type", rules: [Rules.isNumber] },
      { key: "space", optional: true, rules: [Rules.isNumber] },
      { key: "id", rules: [Rules.isNumber] },
      { key: "client_id", rules: [Rules.isNumber] },
      { key: "photos_nb", rules: [Rules.isNumber] },
      { key: "estate_department", optional: true, rules: [Rules.isNumber] },
      { key: "estate_postalcode", rules: [Rules.isString] },
      { key: "client_postalcode", optional: true, rules: [Rules.isString] },
      { key: "country", rules: [Rules.isString] },
      { key: "client_type", rules: [Rules.isString] },
      { key: "investment_type", rules: [Rules.isString] },
      { key: "business_type", rules: [Rules.isString] },
      { key: "parent_site", rules: [Rules.isString] },
      { key: "year_of_construction", rules: [Rules.isNumber] },
      { key: "floor_nb", optional: true, rules: [Rules.isNumber] },
      { key: "nb_rooms", rules: [Rules.isNumber] },
      { key: "energy_letter", rules: [Rules.isString] },
      { key: "nb_bedrooms", optional: true, rules: [Rules.isNumber] },
      { key: "heating_type", optional: true, rules: [Rules.isString] },
      { key: "product_status", rules: [Rules.isString] },
      { key: "media", rules: [Rules.isString] },
      { key: "product_type", rules: [Rules.isString] },
    ],
  },
]

export const LINEUF: DefinitionsInterface = {
  view: {
    event: "view",
    props: [
      { key: "site", rules: [Rules.equal("LINEUF")] },
      {
        key: "search",
        missing: [Rules.isNotSearchPage],
        missingArg: "pagename",
        optional: [Rules.isNotSearchPage],
        optionalArg: "pagename",
        values: [
          { key: "room_nb", rules: [Rules.isNumber] },
          { key: "client_type", rules: [Rules.isString] },
          { key: "advanced_criteria_purchase_type", optional: true, rules: [Rules.isString] },
        ],
      },
      {
        key: "products",
        optional: [Rules.isNotSearchPage, Rules.isNotDetailPage],
        optionalArg: "pagename",
        values: [
          { key: "estate_type", rules: [Rules.isNumber] },
          { key: "distribution_type", rules: [Rules.isNumber] },
          { key: "space", optional: true, rules: [Rules.isNumber] },
          { key: "estate_department", optional: true, rules: [Rules.isNumber] },
          { key: "client_postalcode", optional: true, rules: [Rules.isString] },
          { key: "investment_type", rules: [Rules.isString] },
          { key: "business_type", rules: [Rules.isString] },
          { key: "floor_nb", optional: true, rules: [Rules.isNumber] },
          { key: "nb_bedrooms", optional: true, rules: [Rules.isNumber] },
          { key: "heating_type", optional: true, rules: [Rules.isString] },
          { key: "media", rules: [Rules.isString] },
        ],
      },
    ],
  },
  interactions: [
    {
      event: "interaction",
      int_type: "product",
      int_description: ["click"],
      props: mergeProps(product, [
        { key: "int_infos", rules: [Rules.match(/^search_results-.*$|^detail-similars_bottom$|^detail_client$|^search_results_client$|^homepage$/)] },
      ]),
    },
    {
      event: "interaction",
      int_type: "product",
      int_description: ["impression"],
      props: mergeProps(product, [{ key: "int_infos", rules: [Rules.equalByDependancy], optionalArg: "pagename", haveDependancy: true }]),
    },
    {
      event: "interaction",
      int_type: "form-error",
      int_description: /^.{1,}$/,
      props: mergeProps(product, [{ key: "int_infos", rules: [Rules.isNotEmptyString] }]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["interest"],
      props: mergeProps(product, [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray([
              "detail-get_plan",
              "detail-brochure_top",
              "detail-brochure_bottom",
              "detail-right",
              "detail-bottom",
              "detail-promoteur",
              "detail-responsive",
              "detail-post_favorite",
              "detail-carousel",
              "detail-notaire",
              "detail-more",
              "detail-saving",
              "detail-rental",
              "detail-delivery",
              "detail-financement",
              "detail-brochure-post_contact",
              "search_results-post_favorite",
              "detail_client-top",
              "detail_client-right",
              "detail_client-post_favorite",
              "detail-sticky",
              "deatil-lot",
              "detail-sticky_post_tel",
            ]),
          ],
        },
      ]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["conversion"],
      props: mergeProps(product, [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray([
              "detail-get_plan",
              "detail-brochure_top",
              "detail-brochure_bottom",
              "detail-right",
              "detail-bottom",
              "detail-promoteur",
              "detail-responsive",
              "detail-post_favorite",
              "detail-carousel",
              "detail-notaire",
              "detail-more",
              "detail-saving",
              "detail-rental",
              "detail-delivery",
              "detail-financement",
              "detail-brochure-post_contact",
              "search_results-post_favorite",
              "detail_client-top",
              "detail_client-right",
              "detail_client-post_favorite",
              "post_lead-phone-right",
              "post_lead-phone-bottom",
              "post_lead-phone-promoteur",
              "detail_client-post_lead-phone-right",
              "detail-sticky",
              "deatil-lot",
              "detail-sticky_post_tel",
            ]),
          ],
        },
        { key: "form_profile", rules: [Rules.isInArray(["resident", "investor"])] },
      ]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["confirmation"],
      props: mergeProps(product, [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray([
              "detail-get_plan",
              "detail-brochure_top",
              "detail-brochure_bottom",
              "detail-right",
              "detail-bottom",
              "detail-promoteur",
              "detail-responsive",
              "detail-post_favorite",
              "detail-caroussel",
              "detail-notaire",
              "detail-more",
              "detail-saving",
              "detail-rental",
              "detail-delivery",
              "detail-financement",
              "detail-brochure-post_contact",
              "search_results-post_favorite",
              "detail_client-top",
              "detail_client-right",
              "detail_client-post_favorite",
              "post_lead-phone-right",
              "post_lead-phone-bottom",
              "post_lead-phone-promoteur",
              "detail_client-post_lead-phone-right",
              "detail-sticky",
              "deatil-lot",
              "detail-sticky_post_tel",
            ]),
          ],
        },
        { key: "email_lead", rules: [Rules.isMd5] },
        { key: "form_profile", rules: [Rules.isInArray(["resident", "investor"])] },
      ]),
    },
    {
      event: "interaction",
      int_type: "mail",
      int_description: ["boost"],
      props: [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray([
              "detail-get_plan",
              "detail-brochure_top",
              "detail-brochure_bottom",
              "detail-right",
              "detail-bottom",
              "detail-promoteur",
              "detail-post_favorite",
              "detail-caroussel",
              "detail-notaire",
              "detail-more",
              "detail-saving",
              "detail-rental",
              "detail-delivery",
              "detail-financement",
              "search_results-post_favorite",
              "post_lead-phone-right",
              "post_lead-phone-bottom",
              "post_lead-phone-promoteur",
              "detail_client-post_lead-phone-right",
              "detail-sticky",
              "detail-lot",
              "detail-sticky_post_tel",
            ]),
          ],
        },
      ],
    },
    {
      event: "interaction",
      int_type: "phone",
      int_description: ["conversion"],
      props: mergeProps(product, [
        {
          key: "int_infos",
          rules: [
            Rules.isInArray([
              "detail-right",
              "detail-bottom",
              "detail-responsive",
              "detail-promoteur",
              "detail_client-right",
              "detail_client-responsive",
              "detail-sticky",
              "deatil-lot",
              "detail-sticky_post_tel",
            ]),
          ],
        },
      ]),
    },
    {
      event: "interaction",
      int_type: "favorite",
      int_description: ["add", "remove"],
      props: mergeProps(product, [
        { key: "int_infos", rules: [Rules.isInArray(["detail-top", "detail", "search_results", "detail_client", "homepage", "account-favorites"])] },
      ]),
    },
    {
      event: "interaction",
      int_type: "ali",
      int_description: ["interest"],
      props: [{ key: "int_infos", rules: [Rules.isInArray(["search_results-top", "search_result-bottom", "detail-right"])] }],
    },
    {
      event: "interaction",
      int_type: "ali",
      int_description: ["subscription"],
      props: [
        { key: "int_infos", rules: [Rules.isInArray(["search_results-manual", "detail-manual", "detail-auto"])] },
        { key: "email_lead", rules: [Rules.isMd5] },
        {
          key: "products",
          optional: [Rules.equalByDependancy],
          optionalArg: "int_infos",
          haveDependancy: true,
          values: [
            { key: "list_name", rules: [Rules.isString] },
            { key: "position", rules: [Rules.isNumber] },
            { key: "price", rules: [Rules.isNumber] },
            { key: "estate_type", rules: [Rules.isNumber] },
            { key: "distribution_type", rules: [Rules.isNumber] },
            { key: "space", optional: true, rules: [Rules.isNumber] },
            { key: "id", rules: [Rules.isNumber] },
            { key: "client_id", rules: [Rules.isNumber] },
            { key: "photos_nb", rules: [Rules.isNumber] },
            { key: "estate_department", optional: true, rules: [Rules.isNumber] },
            { key: "estate_postalcode", rules: [Rules.isString] },
            { key: "client_postalcode", optional: true, rules: [Rules.isString] },
            { key: "country", rules: [Rules.isString] },
            { key: "client_type", rules: [Rules.isString] },
            { key: "investment_type", rules: [Rules.isString] },
            { key: "business_type", rules: [Rules.isString] },
            { key: "parent_site", rules: [Rules.isString] },
            { key: "year_of_construction", rules: [Rules.isNumber] },
            { key: "floor_nb", optional: true, rules: [Rules.isNumber] },
            { key: "nb_rooms", rules: [Rules.isNumber] },
            { key: "energy_letter", rules: [Rules.isString] },
            { key: "nb_bedrooms", optional: true, rules: [Rules.isNumber] },
            { key: "heating_type", optional: true, rules: [Rules.isString] },
            { key: "product_status", rules: [Rules.isString] },
            { key: "media", rules: [Rules.isString] },
            { key: "product_type", rules: [Rules.isString] },
          ],
        },
      ],
    },
    {
      event: "interaction",
      int_type: "navigation",
      int_description: ["click"],
      props: [{ key: "int_infos", rules: [Rules.equal("agency-website")] }],
    },
  ],
}

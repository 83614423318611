import { errorStyle, successStyle, warningStyle, infoStyle } from "./styles"
import { LeadResultsInterface } from "./definitions/interface"

const leadResult = (leadsResults: LeadResultsInterface, count: number = 0, parentProps: string[] = []) => {
  let _count = count,
    message = { error: "", warning: "" }

  for (const key in leadsResults) {
    if (leadsResults[key].hasOwnProperty("childs")) _count = leadResult(leadsResults[key].childs, _count, [...parentProps, key])
    else {
      if (!!leadsResults[key].message.error) {
        if (!message.error.length) message.error += `%c${parentProps.map((p) => (p += " -> ")).join("")}`
        _count += 1
        message.error += parentProps.length ? "\n         " : "\n"
        message.error += `${leadsResults[key].message.error}`
      }
      if (!!leadsResults[key].message.warning) {
        if (!message.warning.length) message.warning += `%c${parentProps.map((p) => (p += " -> ")).join("")}`
        message.warning += parentProps.length ? "\n         " : "\n"
        message.warning += `${leadsResults[key].message.warning}`
      }
    }
  }
  if (!!message.error.length) console.log(message.error, errorStyle)
  if (!!message.warning.length) console.log(message.warning, warningStyle)
  return _count
}

export const displayLeadResults = (leadsResults: LeadResultsInterface) => {
  const nbError = leadResult(leadsResults)
  if (nbError === 0) console.log(`%c EVENT IS VALID`, successStyle)
}

export const displayWarningUnknowed = (unknown: LeadResultsInterface) => {
  leadResult(unknown)
}

export const displayStart = (eventName: string) => {
  console.log(`%c START TEST OF ${eventName.toLocaleUpperCase()} EVENT`, infoStyle)
}

export const displayEnd = (eventName: string) => {
  console.log(`%c END TEST OF ${eventName.toLocaleUpperCase()} EVENT`, infoStyle)
}

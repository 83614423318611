import { DefinitionsLeadInterface, DefinitionsPropsInterface, LeadResultsInterface } from "@Interface"
import { testDescription, testProp } from "@CheckEvent"
import { displayLeadResults } from "@Src/display"
import { errorStyle } from "@Src/styles"

export const testLead = (data: { [key: string]: any }, definitionsLead: DefinitionsLeadInterface) => {
  if (!testDescription(data.int_description, definitionsLead.int_description))
    console.log(`%c "int_description" doesn't match any expected decription (found ${data.int_description})`, errorStyle)

  const leadsResults: LeadResultsInterface = {}

  definitionsLead.props.forEach((prop: DefinitionsPropsInterface) => (leadsResults[prop.key] = testProp(data, prop)))

  displayLeadResults(leadsResults)
}
